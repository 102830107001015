import React from 'react';
import Hero from '../hero';
import ArticlesFilters from './filters';

const ArticlesSearchSection: React.FunctionComponent = () => (
  <Hero
    filters={<ArticlesFilters />}
    title="Cherchez dans notre base d'articles"
  />
);

export default ArticlesSearchSection;
