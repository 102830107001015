import ArticlesFilters from '@/components/articles/filters';
import ArticlesSearchSection from '@/components/articles/search-section';
import ArticleThumbnail from '@/components/articles/thumbnail';
import Hero from '@/components/hero';
import Layout from '@/components/layout';
import useStore from '@/store';
import { stat } from 'fs';
import React from 'react';

export interface GraphqlProfilePics {
  [key: string]: string;
}

const Articles = () => {
  const all = useStore((state) => state.articles.all);
  return (
    <Layout>
      <ArticlesSearchSection />
      <section className="section">
        <div className="container">
          <div className="columns is-multiline is-mobile">
            {all.map((article) => (
              <div className="column is-6-mobile is-2-tablet" key={article._id}>
                <ArticleThumbnail article={article} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Articles;
