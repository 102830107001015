import useStore from '@/store';
import { ArticleSuggestion } from '@/store/articles';
import { faNewspaper, faTag, faUser } from '@fortawesome/free-solid-svg-icons';
import { navigate } from 'gatsby';
import React from 'react';
import shallow from 'zustand/shallow';
import FiltersSubmitButton from '../search/filters/submit-button';
import TextFilter from '../search/filters/text-filter';

interface ArticleSearchQueryParams {
  search?: string;
  $limit?: number;
}

const ArticlesFilters: React.FunctionComponent = () => {
  // Hooks
  const [
    currentSuggestion,
    currentSearch,
    find,
    findSuggestions,
    suggestions,
    setCurrentSuggestion,
    setCurrentSearch,
  ] = useStore(
    (state) => [
      state.articles.currentSuggestion,
      state.articles.currentSearch,
      state.articles.find,
      state.articles.findSuggestions,
      state.articles.suggestions,
      state.articles.setCurrentSuggestion,
      state.articles.setCurrentSearch,
    ],
    shallow,
  );
  // Effects
  const fetch = React.useCallback(() => {
    const query: ArticleSearchQueryParams = { $limit: 50 };
    query.search =
      currentSuggestion && currentSuggestion.value
        ? currentSuggestion.value
        : currentSearch;
    find(query);
  }, [currentSuggestion, currentSearch]);

  React.useEffect(() => fetch(), [currentSuggestion, currentSearch]);
  const formRef = React.useRef<HTMLFormElement>();

  React.useEffect(() => {
    findSuggestions();
  }, []);

  return (
    <div className="has-text-centered">
      <form
        ref={formRef}
        onSubmit={(e) => {
          formRef.current.reportValidity();
          e.preventDefault();
          navigate(`/articles`);
        }}
      >
        <div className="columns">
          <div className="column">
            <TextFilter
              suggestions={suggestions}
              currentValue={currentSuggestion?.value}
              fetchSuggestions={(term) => findSuggestions({ term })}
              onSelect={(suggestion: ArticleSuggestion) =>
                setCurrentSuggestion(suggestion)
              }
              onEnterOrBlur={(value: string | ArticleSuggestion) => {
                if (typeof value === `string`) setCurrentSearch(value);
                else if (value.value) setCurrentSuggestion(value);
              }}
              placeholder="Rechercher..."
              itemIcons={{
                title: faNewspaper,
                'category.title': faTag,
              }}
            />
          </div>
          <div className="column is-narrow">
            <FiltersSubmitButton onClick={fetch} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ArticlesFilters;
